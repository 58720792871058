<template>
	<div class="employee_detail" v-loading="loading">
		<div class="agency">
			<div class="tits">
				<div class="tit">会员概况</div><!-- <span>8</span> -->
			</div>
			<div class="contents">
				<div class="item">
					<div class="num">{{member_info.placing_order}}</div>
					<div class="desc">下单数</div>
				</div>
				<div class="item">
					<div class="num">{{member_info.dlivery_num}}</div>
					<div class="desc">发货数</div>
				</div>
				<div class="item">
					<div class="num">{{member_info.pay_num }}</div>
					<div class="desc">支付数</div>
				</div>
				<div class="item">
					<div class="num">{{member_info.refund_num }}</div>
					<div class="desc">维权数</div>
				</div>
				<div class="item">
					<div class="num">{{member_info.inquiry_num}}</div>
					<div class="desc">询价数</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { memberOverview } from '@/api/statistics.js';
	export default {
		data() {
			return {
				id:'',
				member_info:{},
				loading:true
			}
		},
		created() {
			this.id = this.$route.params.pathMatch;
			this.getMemberDetail();
		},
		methods: {
			getMemberDetail(){
				memberOverview({
					member_id:this.id
				})
				.then(res=>{
					if(res.code>=0){
						this.member_info = res.data;
						this.loading = false;
					}
				})
			},
		},
	}
</script>

<style lang="scss">
	.employee_detail{
		min-height: 730px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		flex-direction: column;
		.agency{
			width: 100%;
			background-color: #fff;
			margin-bottom: 18px;
			.tits{
				padding: 14px 0 0 0;
				margin-left: 18px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #F1F1F1;
				.tit{
					color: #333;
					font-size: 14px;
					line-height: 20px;
					border-bottom: 2px solid #00A98F;
					margin-right: 8px;
					padding-bottom: 10px;
				}
				span{
					padding: 1px 6px;
					border-radius: 7px;
					margin-bottom: 11px;
					color: #fff;
					font-size: 12px;
					line-height: 12px;
					background-color: #FE4242;
				}
			}
			.contents{
				display: flex;
				align-items: center;
				padding: 32px 32px 31px 18px;
				.item{
					cursor: pointer;
					flex: 1;
					display: flex;
					align-items: center;
					flex-direction: column;
					padding: 14px 0;
					background-color: #F7F7F7;
					margin-right: 24px;
					.num{
						color: #00A98F;
						font-size: 26px;
						line-height: 36px;
						margin-bottom: 2px;
					}
					.desc{
						color: #333;
						font-size: 12px;
						line-height: 16px;
					}
					&:last-child{
						margin-right: 0;
					}
				}
			}
		}
		.sale{
			width: 100%;
			flex: 1;
			background-color: #fff;
			.titles{
				padding: 14px 0 0 0;
				margin-left: 18px;
				display: flex;
				align-items: flex-start;
				border-bottom: 1px solid #F1F1F1;
				.title{
					padding-bottom: 10px;
					border-bottom: 1px solid #00A98F;
					color: #333;
					font-size: 14px;
					line-height: 20px;
				}
				.mode{
					margin-left: 30px;
					display: flex;
					align-items: center;
					.mode_item{
						cursor: pointer;
						color: #333;
						font-size: 14px;
						line-height: 20px;
						margin-right: 15px;
						&.active{
							color: #00A98F;
						}
					}
				}
			}
			.info{
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
				padding: 32px 32px 0 18px;
				.item{
					width: calc( ( 100% - 24px ) / 2 );
					background-color: #F7F7F7;
					padding: 18px 29px 19px 20px;
					margin-right: 24px;
					box-sizing: border-box;
					margin-bottom: 24px;
					.top{
						color: #333;
						font-size: 14px;
						line-height: 20px;
						margin-bottom: 18px;
					}
					.middle{
						display: flex;
						align-items: center;
						flex-direction: column;
						margin-bottom: 24px;
						.tit{
							display: flex;
							align-items: center;
							color: #333;
							font-size: 14px;
							line-height: 20px;
							margin-bottom: 10px;
							img{
								width: 14px;
								height: 14px;
								display: block;
								margin-left: 5px;
							}
						}
						.money{
							color: #00A98F;
							font-size: 26px;
							line-height: 36px;
						}
					}
					.total_data{
						display: flex;
						align-items: center;
						justify-content: space-between;
						div{
							color: #333;
							font-size: 12px;
							line-height: 17px;
						}
					}
					&:nth-child(2n){
						margin-right: 0;
						margin-bottom: 0;
					}
				}
			}
		}
	}
</style>